import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import './css/app.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Mobile Page List
const Login = React.lazy(() => import('./pages/Login/Login'));
const AppLogin = React.lazy(() => import('./pages/Login/AppLogin'));
const Logout = React.lazy(() => import('./component/Logout'));
const AppLogout = React.lazy(() => import('./component/AppLogout'));
const Search = React.lazy(() => import('./pages/Search'));
const Cal = React.lazy(() => import('./pages/Cal'));
//const Search = React.lazy(() => import('./pages/search/Search_new'));
const DetailSearch = React.lazy(() => import('./pages/search/DetailSearch'));
const MyPage = React.lazy(() => import('./pages/MyPage/MyPage'));
const PrdDetail = React.lazy(() => import('./pages/PrdDetail/PrdDetail'));
const EndSearch = React.lazy(() => import('./pages/EndSearch/EndSearch'));
const EndSearchList = React.lazy(() => import('./pages/EndSearch/EndSearchList'));
const CalendarM = React.lazy(() => import('./pages/calendar/CalendarM'));
const PayingBook = React.lazy(() => import('./pages/Money/PayingBook'));
const DamBoChul = React.lazy(() => import('./pages/dambodaechul/DamBoChul'));
const DamBoChulSecond = React.lazy(() => import('./pages/dambodaechul/DamBoChulSecond'));
const AllDeachul = React.lazy(() => import('./pages/alldaechul/AllDeachul'));
const AllDeachulSecond = React.lazy(() => import('./pages/alldaechul/AllDeachulSecond'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const DetailView = React.lazy(() => import('./pages/search/Detail'));
const AdminDetailView = React.lazy(() => import('./pages/search/AdminDetail'));
class App extends Component {

  render() {
    return (
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/detailview" name="detailview Page" render={props => <DetailView {...props}/>} />
          <Route exact path="/adminDetailview" name="admin detailview Page" render={props => <AdminDetailView {...props}/>} />
          <Route exact path="/admin_login" name="Login Page" render={props => <Login {...props}/>} />
          <Route exact path="/login" name="Login Page" render={props => <AppLogin {...props}/>} />
          <Route exact path="/admin_logout" render={props => <Logout {...props}/>} />
          <Route exact path="/logout" render={props => <AppLogout {...props}/>} />
          <Route exact path="/search" name="search Page" render={props => <Search {...props}/>} />
          <Route exact path="/detail" name="detail Page" render={props => <DetailSearch {...props}/>} />
          <Route exact path="/mypage" name="mypage Page" render={props => <MyPage {...props}/>} />
          <Route exact path="/prd-detail" name="prd-detail Page" render={props => <PrdDetail {...props}/>} />
          <Route exact path="/end" name="end Page" render={props => <EndSearch {...props}/>} />
          <Route exact path="/endlist" name="endlist Page" render={props => <EndSearchList {...props}/>} />
          <Route exact path="/paying" name="paying Page" render={props => <PayingBook {...props}/>} />
          <Route exact path="/dambo" name="dambo Page" render={props => <DamBoChul {...props}/>} />
          <Route exact path="/calendar" name="calendar Page" render={props => <Cal {...props}/>} />
          <Route exact path="/dambo2" name="dambo2 Page" render={props => <DamBoChulSecond {...props}/>} />
          <Route exact path="/all" name="all Page" render={props => <AllDeachul {...props}/>} />
          <Route exact path="/all2" name="al12 Page" render={props => <AllDeachulSecond {...props}/>} />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
          <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
        </Switch>
      </React.Suspense>
      
    );
  }
}

export default App;
